<template>
  <div>
    <div class="alert" :class="retval== 'OK' ? 'alert-success' : 'alert-danger'" role="alert" v-show="resp.length > 0">
      <ul>
        <li v-for="item in resp">{{ item.msg}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resp', 'retval']
}
</script>
