<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-xs-12 col-md-6 offset-md-3">
        <div class="card nw-card">
          <div class="card-body text-left">
              <div class="text-center h2 nw-dark-blue">Admin Console Password Reset</div>
            <div v-show="status=='password'">

              <div class="form-group">
                <label class="control-label">Password</label>
                <input class="form-control" type="password" v-model="pwd1" @keyup="verifyPwd()"/>
              </div>

              <div class="form-group">
                <label class="control-label">Confirm Password</label>
                <input class="form-control" type="password" v-model="pwd2" @keyup="verifyPwd()"/>
              </div>

              <div class="row">
                <div class="col-xs-9 offset-1 col-md-6 offset-md-3">Password Rules:</div>
              </div>
              <div class="row">
                <div class="col-xs-1 offset-2 offset-md-4 text-right"><i class="fa" :class="pwdlen ? 'fa-check text-success' : 'fa-remove text-danger'"></i></div>
                <div class="col">Password Length</div>
              </div>
              <div class="row">
                <div class="col-xs-1 offset-2 offset-md-4 text-right"><i class="fa" :class="pwdnum ? 'fa-check text-success' : 'fa-remove text-danger'"></i></div>
                <div class="col">At least one number</div>
              </div>
              <div class="row">
                <div class="col-xs-1 offset-2 offset-md-4 text-right"><i class="fa" :class="pwdspc ? 'fa-check text-success' : 'fa-remove text-danger'"></i></div>
                <div class="col">At least one of #!_$@.,</div>
              </div>
              <div class="row">
                <div class="col-xs-1 offset-2 offset-md-4 text-right"><i class="fa" :class="pwdmatch ? 'fa-check text-success' : 'fa-remove text-danger'"></i></div>
                <div class="col">Passwords match</div>
              </div>




              <div class="col-xs-10 offset-xs-1 col-lg-6 offset-lg-3" v-show="resp.length > 0">
                <div class="alert alert-danger" role="alert">
                  <div v-for="(item,idx) in resp" :key="idx" :title="item.msg">
                    {{ item.tkey}}
                  </div>
                </div>
              </div>

                <div class="text-center mt-3">
                  <button type="submit" class="btn nw-btn-info text-uppercase" :disabled="ready==false"  @click="resetPassword()">Reset Password</button>
                </div>
              </div>

              <errors :resp="nc.resp" :retval="nc.retval"></errors>

              <div class="text-center mt-3">
                <a href="#/login" type="submit" class="btn nw-btn-outline-info text-uppercase">back to Login</a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

  export default {
    components: { Errors },
    data () {
      return {
        nc: NetClient,
        status: 'password',
        pwd1: '',
        pwd2: '',
        ready: false,
        resp: [],
        pwdnum: false,
        pwdspc: false,
        pwdlen: false,
        pwdmatch: false
      }
    },
    methods: {
      verifyPwd: function() {
        this.pwdlen = this.pwd1.length >= 8
      //  console.log(this.pwd1.length + ' ' + this.pwdlen)
        this.pwdnum = this.pwd1.match('.*[0-9].*')
      //  console.log('pwdnum: ' + this.pwdnum)
        this.pwdspc = this.pwd1.match('.*[#\.,!@$_].*')
        //console.log('pwdnum: ' + this.pwdnum)
        this.pwdmatch = this.pwd1 == this.pwd2

        this.ready = this.pwdnum && this.pwdlen && this.pwdspc && this.pwdmatch
      },
      resetPassword() {
        NetClient.doRequest('login',{action: 'resetPassword', pid: NetClient.resetPid, token: NetClient.resetToken, password: this.pwd1}).then(msg => {

        })
      },
    }
  }

</script>
